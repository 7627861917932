import React, { FC, useEffect, useState } from 'react'
import Typist from 'react-typist'
import Layout, { BUTTON_TYPE_KEY } from '../components/layout'
import { ButtonType } from '../components/MatrixButton/index.models'
import Seo from '../components/seo'
import '../css/experience.css'

interface IExperienceProps {}

const Experience: FC<IExperienceProps> = ({}) => {
    const [showTitle, setShowTitle] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // 👉️ can use localStorage here
            const data: any = window.localStorage.getItem(BUTTON_TYPE_KEY) || null

            if (data) {
                const { selected } = JSON.parse(data)
                setShowTitle(selected === ButtonType.RED)
                // setTimeout(() => {
                //     setShowTitle(false)
                // }, 12000)
            }
        }
    }, [])
    return (
        <Layout>
            <Seo title="Experience | David R Tello Software Developer " />
            {showTitle && (
                <section className="experience">
                    <Typist
                        cursor={{
                            show: false
                        }}
                        avgTypingDelay={40}
                    >
                        <Typist.Delay ms={3500} />
                        <h1 style={{ fontSize: 45, margin: 0 }}>Welcome to the matrix...</h1>
                        <Typist.Backspace count={29} delay={7000} />
                    </Typist>
                    <Typist
                        cursor={{
                            show: false
                        }}
                        avgTypingDelay={300}
                    >
                        <Typist.Delay ms={8000} />
                        <strong style={{ fontSize: 45, color: 'rgb(9, 236, 77)' }}>Neo.</strong>
                        <Typist.Backspace count={29} delay={1000} />
                    </Typist>
                </section>
            )}
        </Layout>
    )
}

export default Experience
